import React, { useCallback, useEffect, useState } from 'react';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { isUUID } from 'validator';

import { AddButton, Card } from '../styles';

import plus from '~/assets/icons/plus-admin-icon2.svg';
import facebook from '~/assets/icons/facebook-icon.svg';
import twitter from '~/assets/icons/twitter-icon.svg';
import whatsapp from '~/assets/icons/whatsapp-circle-icon.svg';
import api from '~/services/api';

interface ISocialProps {
  isPostEase: boolean;
}

interface ISwipeFile {
  id: string;
  title: string;
  description: string;
  content: string;
}

interface ISocial {
  id: string;
  title: string;
  subtitle: string;
  body: string;
  edited: boolean;
}

const Social: React.FC<ISocialProps> = ({ isPostEase }) => {
  const [socials, setSocials] = useState<ISocial[]>([]);

  useEffect(() => {
    api
      .get<ISwipeFile[]>('swipe-files', {
        params: {
          type: 'social post',
          category: isPostEase ? 'postease' : 'autoaffiliate',
        },
      })
      .then((response) => {
        const data = response.data.map<ISocial>((swipeFile) => ({
          id: swipeFile.id,
          title: swipeFile.title,
          subtitle: swipeFile.description,
          body: swipeFile.content,
          edited: false,
        }));

        setSocials(data);
      });
  }, [isPostEase]);

  const handleClickSave = useCallback(
    async (index) => {
      const newSocials = [...socials];

      const formData = {
        type: 'social post',
        category: isPostEase ? 'postease' : 'autoaffiliate',
        title: newSocials[index].title,
        description: newSocials[index].subtitle,
        content: newSocials[index].body,
      };

      let socialId = newSocials[index].id;

      if (isUUID(socialId, 4)) {
        await api.put(`swipe-files/${socialId}`, formData);
      } else {
        const response = await api.post('swipe-files', formData);
        socialId = response.data.id;
      }

      newSocials[index].id = socialId;
      newSocials[index].edited = false;
      setSocials(newSocials);
    },
    [isPostEase, socials]
  );

  const handleClickDelete = useCallback(
    async (selectedId) => {
      await api.delete(`swipe-files/${selectedId}`);

      const updatedItems = socials.filter((item) => item.id !== selectedId);

      setSocials(updatedItems);
    },
    [socials]
  );
  //
  const handleAddLink = useCallback(() => {
    const nextId =
      socials.length > 0
        ? (parseInt(socials[socials.length - 1].id, 10) + 1).toString()
        : '1';
    setSocials((state) => [
      ...state,
      {
        id: nextId,
        title: '',
        subtitle: '',
        body: '',
        edited: false,
      },
    ]);
  }, [socials]);

  const handleChangeTitleSocial = useCallback(
    (event, index) => {
      const newSocial = [...socials];
      if (event.target) {
        const { value } = event.target;
        newSocial[index].title = value;
        newSocial[index].edited = true;
        setSocials(newSocial);
      }
    },
    [socials]
  );

  const handleChangeSubtitleSocial = useCallback(
    (event, index) => {
      const newSocial = [...socials];
      if (event.target) {
        const { value } = event.target;
        newSocial[index].subtitle = value;
        newSocial[index].edited = true;
        setSocials(newSocial);
      }
    },
    [socials]
  );

  const handleChangeBodySocial = useCallback(
    (event, index) => {
      const newSocial = [...socials];
      if (event.target) {
        const { value } = event.target;
        newSocial[index].body = value;
        newSocial[index].edited = true;
        setSocials(newSocial);
      }
    },
    [socials]
  );

  return (
    <>
      <div className="col-lg-12 my-3">
        <div className="d-flex w-100 my-5 my-md-4 my-lg-0 justify-content-center justify-content-sm-start justify-content-lg-end">
          <AddButton
            type="button"
            className="h6 mb-0 py-2 px-4 small normal-sm"
            onClick={handleAddLink}
          >
            <img src={plus} alt="plus" className="my-1 mr-3" />
            Add New Post
          </AddButton>
        </div>
      </div>
      {socials.map((social, index) => (
        <div key={index} className="col-md-6 col-xl-4 my-3">
          <Card className="p-3 p-sm-4 h-100">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div />
              {social.edited ? (
                <button
                  type="button"
                  className="rounded-pill btn-top py-2"
                  onClick={() => handleClickSave(index)}
                >
                  <span className="mb-0 small m-1">Save</span>
                </button>
              ) : (
                <button
                  type="button"
                  className="d-flex align-items-center justify-content-center rounded-pill btn-top py-2 text-danger"
                  onClick={() => handleClickDelete(social.id)}
                >
                  <RiDeleteBin6Line size={15} />
                  <span className="mb-0 small ml-2">Delete</span>
                </button>
              )}
            </div>

            <h3 className="h3-social pt-2 mb-0 mr-2">{`Post ${index + 1}:`}</h3>
            <textarea
              placeholder="Title Here..."
              className={`h3-social w-100 py-2 flex-1 bg-transparent border-0 `}
              value={social.title}
              onChange={(e) => handleChangeTitleSocial(e, index)}
            />

            <div className="subject link d-flex justify-content-between align-items-end py-1 p-relative">
              <textarea
                placeholder="Subtitle Here..."
                className={`h3-social py-2 w-100 bg-transparent border-0 `}
                value={social.subtitle}
                onChange={(e) => handleChangeSubtitleSocial(e, index)}
              />
            </div>

            <div className="body body-social d-flex justify-content-between align-items-end py-1 p-relative">
              <textarea
                placeholder="Text Here..."
                className={`py-2 w-100 bg-transparent border-0 `}
                value={social.body}
                onChange={(e) => handleChangeBodySocial(e, index)}
              />
            </div>
            <div className="d-flex justify-content-center flex-wrap mt-4 pb-xxl-3">
              <small className="w-100 text-center mb-3">
                Share on social media
              </small>
              <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                <button
                  type="button"
                  className="btn-share shadow rounded-pill w-100"
                >
                  <img src={facebook} alt="Facebook" className="my-2" />
                </button>
              </div>
              <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                <button
                  type="button"
                  className="btn-share shadow rounded-pill w-100"
                >
                  <img src={twitter} alt="Twitter" className="my-2" />
                </button>
              </div>
              <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                <button
                  type="button"
                  className="btn-share shadow rounded-pill w-100"
                >
                  <img src={whatsapp} alt="Whatsapp" className="my-2" />
                </button>
              </div>
            </div>
          </Card>
        </div>
      ))}
    </>
  );
};

export default Social;
