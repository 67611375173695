import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import axios, { CancelTokenSource } from 'axios';

import api from '~/services/api';

import { Container, Welcome, NameUserTable, OptionUser } from './styles';
import Table from '~/components/Table';
import options from '~/assets/icons/options.svg';

interface ITableData {
  dataJoined: string;
  userID: string;
  avatar: string;
  name: string;
  member_type: string;
  referrer: string;
  referrerName: string;
  produtc_owned: string;
  email: string;
  phone: string;
  created_at: string;
}

interface ICourseUser {
  course: {
    title: string;
  };
}

interface IUser {
  id: string;
  created_at: string;
  referral_code: string;
  origin: string;
  avatar: {
    avatar_url: string;
  };
  name: string;
  courseUser: ICourseUser[];
  email: string;
  phone: string;
  affiliate: {
    type: string;
    affiliatesOffers: {
      offer_id: string;
    }[];
  };
  referrer: {
    affiliate: {
      user: {
        name: string;
      };
    };
  };
}

let cancelToken: CancelTokenSource | undefined;

const Users: React.FC = () => {
  const hisotry = useHistory();
  const [users, setUsers] = useState<ITableData[]>([]);
  const [loading, setLoading] = useState(false);

  const handleLoadUsers = useCallback(async (filterData = '') => {
    cancelToken = axios.CancelToken.source();
    const response = await api.get('users', {
      cancelToken: cancelToken.token,
      params: {
        filter:
          filterData || sessionStorage.getItem('@AutoAffiliate:searchUser'),
        isAdmin: true,
      },
    });
    const data = response.data.map((user: IUser) => {
      const originParts = user.origin.split(', ');
      return {
        created_at: user.created_at,
        dataJoined: format(parseISO(user.created_at), 'yyyy-MMM-dd'),
        userID: user.id,
        avatar: user.avatar?.avatar_url,
        name: user.name,
        member_type: user.affiliate?.type || 'New Member',
        referrerName: user.referrer?.affiliate?.user?.name || '-',
        referrer: user.referral_code,
        origin: originParts[0],
        produtc_owned:
          user.courseUser.length > 0
            ? user.courseUser[0].course.title
            : 'Without course',
        email: user.email,
      };
    });
    setUsers(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);

    handleLoadUsers();
  }, [handleLoadUsers]);

  const handleSelectedUser = useCallback(
    (e) => {
      hisotry.push(`/users/update/${e.userID}`);
    },
    [hisotry]
  );

  const handleSearch = useCallback(
    (value) => {
      if (cancelToken) {
        cancelToken.cancel('canceled');
      }
      sessionStorage.setItem('@AutoAffiliate:searchUser', value);
      handleLoadUsers(value);
    },
    [handleLoadUsers]
  );

  const columns = useMemo(
    () => [
      {
        name: 'Date Joined',
        selector: 'created_at',
        sortable: true,
        cell: (row: ITableData) => row.dataJoined,
      },
      // {
      //   name: 'User ID',
      //   selector: 'userID',
      //   sortable: true,
      // },
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
        cell: (row: ITableData) => (
          <NameUserTable
            data-tag="allowRowEvents"
            className="d-flex align-items-center"
          >
            <img src={row.avatar} alt={row.name} className="mr-2" />
            <p className="mb-0">{row.name}</p>
          </NameUserTable>
        ),
      },
      {
        name: 'Origin',
        selector: 'origin',
        sortable: true,
      },
      {
        name: 'Member Type',
        selector: 'member_type',
        sortable: true,
      },
      {
        name: 'Referrer ',
        selector: 'referrer',
        sortable: true,
      },
      {
        name: 'Affiliate',
        selector: 'referrerName',
        sortable: true,
      },
      {
        name: 'Product Owned ',
        selector: 'produtc_owned',
        sortable: true,
      },
      {
        name: 'Email ',
        selector: 'email',
        sortable: true,
      },
      {
        name: '',
        selector: 'Options',
        sortable: true,
        cell: (row: ITableData) => (
          <OptionUser
            data-tag="allowRowEvents"
            className="d-flex align-items-center"
            onClick={() => handleSelectedUser(row)}
          >
            <img src={options} alt="options" className="mr-2" />
          </OptionUser>
        ),
      },
    ],
    [handleSelectedUser]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="h4 h2-lg mb-4">Users Management</h1>
                  </div>
                </div>
              </div>
            </Welcome>
            <Table
              data={users}
              columns={columns}
              pagination
              date
              searchable
              onSearch={handleSearch}
              exportable
              searchValue={
                sessionStorage.getItem('@AutoAffiliate:searchUser') || undefined
              }
            />
          </div>
        </div>
      </div>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Users;
